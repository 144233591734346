<template>
  <div class="allWrap d-flex flex-column h-100">

    <div class="routerViewContainer" ref="routerViewContainer">
      <div class="item-page">
        <router-view/>
<!--        <div class="debug"><pre>{{ $store.state.order }}</pre></div>-->
      </div>
        <FooterView/>


    </div>
    <vue-cookie-comply
      :preferences="preferences"
      @on-accept-all-cookies=""
      @on-save-cookie-preferences=""
      headerDescription="Wir verwenden Cookies, um Ihnen den bestmöglichen Service bieten zu können. Durch die weitere Nutzung der Website erklären Sie sich damit einverstanden."
      headerTitle="Cookies"
      preferencesLabel="Einstellungen"
      acceptAllLabel="Einverstanden"
    >
      <template v-slot:modal-header>
        <header>Cookie-Einstellungen</header>
      </template>
    </vue-cookie-comply>
    <div class="newVersionTest" @click="$store.state.publishNewVersion = false;"
         v-if="$store.state.publishNewVersion">
      <div class="VersionWarningWrap">
        <h2 class="mb-3">Neue Version verfügbar!</h2>
        <a href="/" class="button btn btn-success mb-3">Hier klicken!</a>
        <p class="versionInfo">Version: {{ $store.state.version }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import environment from "./auth/environment"
import router from "@/router";
import FooterView from "@/components/footer.vue";
import axios from 'axios'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['checkLogin', 'getUser'])
  },
  components: {
    FooterView
  },
  methods: {
    ...mapActions(['logout', "checkToken"]),
    toggleNav() {
      if (this.showNav === false) {
        this.showNav = true;
      } else {
        this.showNav = false;
      }
    },
    doGuestLogin() {
      //this.$store.state.loggedInState = true
      //this.$store.state.scope = { scope: 'guest', write: false, loggedOut: false }
      this.guestLogin = true;
      router.push('/reorder');
    },
    logoutInline() {
      localStorage.removeItem('sketchManagerToken');
      this.$store.state.loggedInState = null
      this.$store.state.scope = {scope: null, write: false, loggedOut: true}
      this.$store.state.acl = null
      this.$store.state.user = null
      this.$store.state.currentProgress = 1
      this.$store.state.currentApplication = null
      this.$store.state.user = null
      router.push('/');
      window.location.href = environment.logoutUrlComplete;
    },

  },

  data() {
    return {
      showNav: false,
      open: false,
      credentials: {
        email: "",
        password: ""
      },
      activeMenu: 'Dashboard',
      passwordType: 'password',
      menuState: 'closed',
      guestLogin: false,
      preferences: [
        {
          title: 'Systemrelevante Cookies',
          description: 'Diese Cookies sind für die Funktion der Website notwendig und können nicht deaktiviert werden.',
          items: [{label: 'technische Cookies', value: 'technical', isRequired: true}],
        },
      ]
    }
  },
}
</script>
<style lang="scss">
@import "./assets/scss/styles.scss";

p.versionsInfo {
  line-height: 40px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
}
button.showNavButton.d-inline-block.d-lg-none.btn.btn-primary.btn-sm.float-end {
  position: absolute;
  right: 12px;
  top: 23px;
}

.newVersionTest {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  top: 0;
  background: #ffffffe0;
  padding: 20px;
  text-align: center;
  padding-top: 10vh;
}

.ps {
  height: 60vh;
}

.file-uploads.btn {
  padding: 2px !important;
}
</style>
